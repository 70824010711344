<!--  -->
<template>
  <div class="bod">
    <Breadcrumb style="margin-top: 20px;margin-left: 163px;margin-bottom: 20px;color: rgb(110, 114, 117);">
      <BreadcrumbItem to="/">首页</BreadcrumbItem>
      <BreadcrumbItem to="/message">相关资讯</BreadcrumbItem>
      <BreadcrumbItem>详情</BreadcrumbItem>
    </Breadcrumb>
    <div class="flex">
      <div class="biaoti">
        黄埔区妇联、人社局联合调研朝九晚四巾帼创客中心
      </div>
    </div>
    <div class="flex">
      <div class="xgleft3"></div>
      <div class="xgleft4">各界关怀</div>
      <div class="xgleft1">发布时间：&nbsp&nbsp2023.07.11</div>
    </div>
    <div class="flex">
      <div class="wenben1">
        7月10日上午，黄埔区妇联主席王晓虹、人社局副局长黄智祥、区妇联副主席宋赫男、区就业管理中心主任綦宗彬、就业处王晨等领导一行，就省人社厅、省妇联发布的《关于推行“妈妈岗”模式，促进妇女就业的实施意见》的征求意见稿和朝九晚四巾帼创客中心近年的运营情况，到朝九晚四巾帼创客中心深入调研。并为线上平台“宝妈灵活就业网”的运营提出建议和意见。
      </div>
    </div>
    <div class="flex">
      <div class="page1tu1"></div>
    </div>
    <div class="flex">
      <div class="wenben2">中心理事长王盛虎、总干事邓文娟接待了联合调研组，并就育儿妈妈灵活就业的社会调查、中心的运营情况、宝妈灵活就业网的运营规划作了详细汇报。</div>
    </div>
    <div class="flex">
      <div class="page1tu2"></div>
    </div>
    <div class="flex">
      <div class="wenben3"> 黄智祥副局长对朝九晚四创客中心，以社会公益力量，花大力气、动大脑筋，为我区妇女关爱和灵活就业提供创新模式表示肯定，对打造育儿妈妈灵活就业的“黄埔模式”予以鼓励，表示要在区人社部门的公共宣传平台上，向区内企业进行统一推介，以协助解决当前企业知晓度不够和朝九晚四岗位缺乏的问题；建议朝九晚四嵌入政府部门统一规划的“求职驿站”，开发和承接政府部门举办的面向育儿妈妈技能培训，融入全区大就业系统，获得政府和财政支持，维持中心的可持续发展。同时鼓励朝九晚四巾帼创客中心积极评选政府组织的示范项目和创新项目。
        <br>
        <div style="text-indent: 2em;">王晓虹主席高度肯定朝九晚四巾帼创客中心在过去两年的努力和坚守，建议中心可链接多方资源，加强倡导力度，多渠道开发岗位，扩大项目覆盖范围，让更多育儿妈妈受惠，把“每个放学回家孩子，都能吃到妈妈做的热饭”落到实处。</div>
      </div>
    </div>
    <div class="flex">
      <div class="page1tu3"></div>
    </div>
    <div class="flex">
      <div class="wenben4"> Tips:朝九晚四巾帼创客中心线上平台，已正式更名为宝妈灵活就业网</div>
    </div>
    <div class="flex">
      <div class="wenben5"> 全国首个面向育儿妈妈群体的大型灵活就业平台，以“让每个放学回家的孩子，都能吃上妈妈做的热饭”为使命，以“朝九晚四（早上9点上班，下午4点下班）”为产品特质，以珠三角为中心，凝聚并打造中国孩子3-12岁（幼儿园到小学）的5000万育儿妈妈灵活就业的专业门户。让她们既能照顾家庭，又能灵活就业创造收入，融入社会，实现自我，同时也能响应国家号召，减轻育儿妈妈的工作和家庭压力，提升生育率，具有巨大且深远的社会公益意义，利国利民！宝妈灵活就业网一经上线后，便得到政府、妇联、各大媒体和社会各界的高度关注和支持。</div>
    </div>
    <div class="flex">
      <hr class="hr1" />
    </div>
    <div style="margin-left: 163px;">
      <div class="next">下一篇</div>
      <div class="nextkuang">
        <div class="xgleft1">2023.06.01</div>
        <router-link to="/page2" class="text1">区创投项目 ——“促进就业，宝妈创业”线下宣传招募活动</router-link>
        <div class="xgleft3"></div>
        <div class="xgleft4">各界关怀</div>
        <router-link to="/page2" class="text2">7月10日上午，黄埔区妇联主席王晓虹、人社局副局长黄智祥、区妇联副主席宋赫男、区就业管理中心主任綦宗彬、就业处王晨等领导一行，就省人社厅、省妇联发布的《关于推行“妈妈岗”模式，促进妇女就业的实施意见》的征求意见稿和朝九晚四巾帼创客中心近年的运营情况，到朝九晚四巾帼创客中心深入调研......</router-link>
        <router-link to="/page2">
          <div class="text3">查看详情<img src="../../../assets/images/you.png"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./page.less";
</style>
